import React, { useState, useEffect, useRef } from "react";

const HomeView = () => {
    const logoSize = 60;
    
  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center relative z-10">
        <video 
            src="/video/grape-20fps.mp4" 
            autoPlay
            loop
            muted
            playsInline
            className="object-cover w-full h-full absolute -z-1 blur"
            poster="/images/video-fallback.jpg"
        ></video>


            <div className="relative z-1 flex gap-x-2 sm:gap-x-4 items-center">
                <img src="/images/logo.png" alt="Vitilog" className="w-10 h-10 sm:w-[60px] sm:h-[60px]"/>
                <div className="text-5xl sm:text-7xl font-bold text-white">Vitilog</div>
            </div>

        <div className="absolute bottom-1 text-white p-2 text-xs">Powered by 
            <a href="https://agantar.com" target="blank" className="px-1 font-bold text-slate-700 uppercase">Agantar</a>
            </div>
            
    </div>
  );
}

export default HomeView;